import "twin.macro";
import { memo, useEffect, useState } from "react";
import TopbarMenus from "./TopbarMenus";
import { ECommerce } from "api/AppConfigApi";
import { getAllBranch } from "api/BranchDetailsApi";
import {setBranchId} from "../../utility/HeaderConfig.js";
import { useHistory } from "react-router-dom";
import { useCartContext } from "context/UseCartContext";

function Topbar({ branch }) {
  const [media, setMedia] = useState([]);
  const [branches, setBranches] = useState([]);
  
  const { dispatch } = useCartContext();

   let history = useHistory();
   
  useEffect(() => {
    const getAllBrachDetails = () => {
      getAllBranch({}).then((data) => {
        setBranches(data.response.content);
      });
    };
    getAllBrachDetails();
    socialMedia();
  }, []);

  const socialMedia = () => {
    let filter = "social_media_config";
    ECommerce(filter).then((data) => {
      let SOCIALMEDIA = JSON.parse(data.response.moduleField);
      setMedia(SOCIALMEDIA);
    });
  };

  const selectBranch = (branchId)=>{
    setBranchId(branchId);
    dispatch({type: "BRANCHID", payload: branchId});
    history.push("/");
  }

  return (
    <div tw=" bg-custom-200">
      <div tw="max-w-full px-4 sm:px-6 lg:px-8 m-auto flex items-center justify-center sm:justify-between h-10">
        <div tw="xl:flex-1 lg:flex-grow-0 lg:inline-flex hidden items-center">
          <i className="bi bi-shop" tw="text-xl font-semibold"></i>
          {/* <span tw="lg:text-xs xl:text-sm font-normal ml-1">
            Need Help? call us: {branch.mobile}
          </span> */}
          <span
            className="hover-trigger ms-2"
            tw="text-xs sm:text-sm lg:text-xs xl:text-sm relative cursor-pointer"
          >
            Branch : {branch.branchName}
            {/* <i className="bi bi-caret-down" tw="text-base mx-1"></i> */}
            <div
              className="hover-target"
              tw="absolute right-0 text-left bg-white w-40   py-2 rounded-md shadow-xl"
            >
              {branches.map(
                (data) =>
                  branch.branchName !== data.branchName && (
                    <span tw="no-underline text-black hover:bg-[#faeae2] p-2 hover:text-black block  hover:(-translate-y-2 bg-custom-200) duration-300 transition ease-in-out delay-150 hover:shadow-lg" onClick={()=>selectBranch(data.id)}>
                      <i
                        className="bi bi-arrow-return-right"
                        tw=" px-3 text-base"
                      ></i>
                      {data.branchName}
                    </span>
                  )
              )}
            </div>
          </span>
        </div>
        {/* <div tw="hidden sm:flex text-center">
          {media.map((data, index) => (
            <a href={data.value} tw="text-black" target="blank" key={index}>
              <i
                className={`bi bi-${data.key?.toLowerCase()}`}
                tw="text-base mx-2 md:mx-4  hover:text-rose-500 cursor-pointer"
              ></i>
            </a>
          ))}
        </div> */}
        {/* <TopbarMenus /> */}
      </div>
    </div>
  );
}

export default memo(Topbar);
