import tw from "twin.macro";
import Carousel from "./components/Carousel.js";
import ProductCategory from "./components/ProductCategory.js";
import GenderBased from "./components/GenderBasedProduct.js";
import ProductType from "./components/ProductType";
import ArrivalsLiked from "components/ArrivalsLiked.js";
import SocialMedia from "components/SocialMedia.js";
import AppQRCODE from "./images/app-qrcode.png";
import googleImg from "./images/google-play-6647242_1280.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";

const Container = tw.div`relative font-display overflow-hidden`;
const retailShop = "https://images.zinger.co.in/shop_images/sks_nithym.jpg";
const storeName = "SKS NITHYAM JEWELLERY";
export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */



  return (
    <Container>
      <Carousel />
      <div className="container" tw="lg:py-16 py-10 ">
        <div className="row align-items-center flex-column-reverse flex-lg-row py-2">
          <div className="col-md-12 col-lg-6">
            <h1 tw="text-5xl my-5 lg:mt-2">WHO WE ARE!!</h1>
            <div className="ptSans text-base">
              <p tw="text-zinc-300 ">
            
{storeName} we provide wide range of services like sales of Gold and Silver jewellery, articles and gifts, Saving Schemes, Exchange offers, Jewellery Repairs, Easy EMI and Jewellery Insurance. {storeName}  is a trademark today, in its segment and for its customers.
Our honest and transparent dealings has own confidence, trust and a special place in the hearts of customers.

              </p>
              <h2>Our Vision</h2>
              <p tw="text-zinc-300 ">
We have a simple vision to serve our customer and gain their credibility.</p>
<h2> Our Mission</h2><p tw="text-zinc-300 ">
Our mission is to treat all customers at par, be transparent and honest.</p>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 ">
            <img
              src={retailShop}
              alt="About-Img"
              tw="absolute right-0 top-0 -z-10 hidden lg:block w-5/12"
            />

          </div>
        </div>
        <div className="row py-5 mt-lg-2 align-items-center">
          <div className="col-md-5 col-lg-3">
            {/* <img
              src={AppQRCODE}
              alt="App QR Code"
              className="w-100"
              tw="rounded-xl"
            /> */}
          </div>
          <div className="col-md-4 col-lg-4">

            <div className="ptSans text-base text-center">
              <a
                href="https://play.google.com/store/apps/details?id="
                target="_blank"
                rel="noreferrer"
              >

                <h3 tw="text-zinc-300 ">
                  Download Now
                </h3>
                <img
                  src={googleImg}
                  alt="App QR Code"
                  className="w-50"
                  tw="rounded-xl"
                  style={{ margin: "0 auto" }}
                />
              </a>
              <h3>The app for all your <br /> {storeName}  <br />Jewellery process.</h3>

            </div>

          </div>
        </div>


      </div>

      {/* <ProductType />
      <ProductCategory />
      <ArrivalsLiked />
      <GenderBased /> */}
      <SocialMedia />
    </Container>
  );
};
